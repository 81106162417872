/*
# **********************************************************************
#
#  (c) 2020 Tobias Fleckenstein <tfleckenstein@mp-group.net>, mp group GmbH
#
# **********************************************************************
# date/time    : 14.01.2020
# project      : projekt
# developer    : Tobias Fleckenstein
#
#   .-------------'```'----....,,__                        _,
#   |                               `'`'`'`'-.,.__        .'(
#   |                                             `'--._.'   )
#   |                                                   `'-.<
#   \               .-'`'-.                            -.    `\
#    \               -.o_.     _                     _,-'`\    |
#     ``````''--.._.-=-._    .'  \            _,,--'`      `-._(
#       (^^^^^^^^`___    '-. |    \  __,,..--'                 `
#        `````````   `'--..___\    |`
#                              `-.,'
# **********************************************************************
*/

